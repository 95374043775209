// Import FirebaseAuth and getFirebase().
import React, {useEffect, useState} from 'react';

import Layout from "../components/layout";

function LegalNotice({}) {

    return (
        <Layout>
            <div className="pt-24 pb-1">
                <div className="mx-auto">
                    <div className="w-full mx-auto">
                        <div>
                            <div className='py-14 text-center text-3xl'>
                                <h1>Politique de confidentialité de eMenila</h1>
                            </div>
                            <br/>
                            <div className='bg-white text-gray-600'>
                                <div className='container w-full mx-auto py-10'>
                                    <p align="center">
                                        <em>Document mis à jour le 17 août 2021</em>
                                        <em></em>
                                    </p>
                                    <p>
                                        <strong>eMenila</strong>
                                        (‘’<strong>eMenila</strong>’’, ‘’notre’’, ‘’nous’’ et
                                        ‘’nos’’) et nos partenaires respectent votre vie privée.
                                    </p>
                                    <p>
                                        Nous vous demandons de bien vouloir lire attentivement la présente
                                        politique de confidentialité pour comprendre comment sont collectées,
                                        traitées et conservées vos données personnelles lors de votre utilisation
                                        du présent site internet <strong>eMenila</strong>, accessible via l’url
                                        https://eMenila.fr/.
                                    </p>
                                    <p>
                                        L’ensemble des données personnelles collectées sur le présent site internet
                                        sont traitées sous la responsabilité de l’entrepreneur Raidouane EL
                                        MOUKHTARI, immatriculé au Registre du Commerce et des Sociétés de Bordeaux    <strong> </strong>sous le numéro 830334199.
                                    </p>
                                    <p>
                                        Au sens de la réglementation applicable aux données personnelles, Raidouane
                                        El Moukhtari<strong> </strong>est donc responsable de traitement.
                                    </p>
                                    <p>
                                        Cette politique de confidentialité décrit :
                                    </p>
                                    <p>
                                        1. Comment eMenila utilise vos données personnelles
                                    </p>
                                    <p>
                                        2. Comment eMenila partage vos données personnelles
                                    </p>
                                    <p>
                                        3. Comment eMenila protège vos données personnelles
                                    </p>
                                    <p>
                                        4. Où eMenila héberge et transfère vos données personnelles
                                    </p>
                                    <p>
                                        5. Comment vous pouvez exercer vos droits relatifs à vos données
                                        personnelles
                                    </p>
                                    <p>
                                        6. Les mises à jour de la politique de confidentialité
                                    </p>
                                    <p>
                                        7. Comment nous contacter
                                    </p>
                                    <p>
                                        <strong> </strong>
                                    </p>
                                    <p align="center">
                                        __________
                                    </p>
                                    <p>
                                        <strong> </strong>
                                    </p>
                                    <p>
                                        <strong>
                                            I. Comment eMenila utilise vos données personnelles
                                        </strong>
                                    </p>
                                    <p>
                                        eMenila peut utiliser vos données personnelles aux fins
                                        suivantes :
                                    </p>
                                    <p>
                                        (1) Créer votre compte client sur le présent site internet
                                    </p>
                                    <p>
                                        (2) Gérer les commandes de produits et/ou services
                                    </p>
                                    <p>
                                        (3) Publier et gérer les avis laissés sur les produits et/ou services
                                        commandés sur le présent site internet
                                    </p>
                                    <p>
                                        (4) Répondre à votre demande de contact effectuée à partir de notre site
                                        internet
                                    </p>
                                    <p>
                                        La plupart des traitements listés ci-dessus sont nécessaire à l’exécution
                                        du contrat passé avec <strong>eMenila</strong> lorsque vous
                                        utilisez notre site internet afin de commander les produits et/ou services
                                        disponibles à la vente sur le site.
                                    </p>
                                    <p>
                                        <strong>
                                            II. Comment eMenila partage vos données personnelles
                                        </strong>
                                    </p>
                                    <p>
                                        Au sein de eMenila, et au regard de chaque finalité de
                                        traitement, les données personnelles vous concernant sont collectées,
                                        traitées et stockées par le personnel habilité de eMenila,
                                        uniquement dans le cadre de leurs compétences respectives, et notamment par
                                        le service client, le département marketing et le département informatique.
                                    </p>
                                    <p>
                                        Nous ne partageons pas les données personnelles avec d’autres entreprises,
                                        organisations et personnes, à moins que l’une des circonstances suivantes
                                        s’applique :
                                    </p>
                                    <p>
                                        <strong>(1)</strong>
                                        <strong>Le partage avec consentement préalable</strong>
                                        : après avoir obtenu votre consentement, eMenila    <strong> </strong>partagera les informations que vous avez autorisées avec
                                        les tiers ou catégories de tiers spécifiques renseignés à l’occasion de la
                                        collecte de votre consentement.
                                    </p>
                                    <p>
                                        <strong>(2) Le partage avec nos prestataires</strong>
                                        : eMenila peut également divulguer vos informations à des
                                        entreprises qui fournissent des services pour nous ou en notre nom. Ces
                                        prestataires de services comprennent des entreprises qui proposent des
                                        services informatiques tels notamment notre hébergeur ou notre prestataire
                                        d’envoi d’emails, des services de livraison de nos produits, ou qui
                                        proposent des activités marketing pour notre compte. Ces prestataires de
                                        services peuvent utiliser vos informations uniquement dans le but de vous
                                        fournir des services au nom de eMenila.
                                    </p>
                                    <p>
                                        <strong>
                                            (3) En exécution d’une obligation légale, le partage conformément aux
                                            lois et à la réglementation
                                        </strong>
                                        : eMenila, peut partager vos informations comme le stipulent
                                        les lois et la réglementation, afin de résoudre les différends d’ordre
                                        juridique, ou comme le stipulent les autorités judiciaires ou
                                        administratives en vertu de la loi.
                                    </p>
                                    <p>
                                        eMenila s’assurera de la légalité de tout partage des
                                        données personnelles via des clauses de traitement de données avec les
                                        entreprises avec lesquelles vos données personnelles sont partagées, les
                                        obligeant à se conformer à cette politique de confidentialité et à prendre
                                        les mesures de sécurité et confidentialité appropriées lors du traitement
                                        de données à caractère personnel.
                                    </p>
                                    <p>
                                        <strong>
                                            III. Comment eMenila protège vos données personnelles
                                        </strong>
                                    </p>
                                    <p>
                                        eMenila<strong> </strong>attache une grande importance à la
                                        sécurité de vos données personnelles et a adopté des pratiques courantes de
                                        l'industrie afin de protéger vos données personnelles et d'éviter l'accès
                                        non autorisé, la divulgation, l'utilisation, la modification, les dommages
                                        ou la perte de ces informations.
                                    </p>
                                    <p>
                                        Nous avons par ailleurs pris les précautions utiles afin et faire préserver
                                        par notre hébergeur la sécurité et la confidentialité des données et
                                        notamment empêcher qu’elles ne soient déformées, endommagées ou
                                        communiquées à des personnes non autorisées.
                                    </p>
                                    <p>
                                        eMenila adopte également les mesures organisationnelles
                                        suivantes :
                                    </p>
                                    <p>
                                        (1) Nous adoptons des mesures raisonnables et réalisables afin de veiller à
                                        ce que les données personnelles recueillies soient minimes et pertinentes
                                        selon ce qui est nécessaire, eu égard aux fins pour lesquelles elles sont
                                        traitées.
                                    </p>
                                    <p>
                                        (2) Nous conservons vos données personnelles pendant la durée qui est
                                        strictement nécessaire au regard de la finalité du traitement, à moins que
                                        la conservation de vos données soit requise ou permise par la loi. A titre
                                        d’exemple, nous conservons les données liées à l’exécution de vos commandes
                                        pour la durée requise par la loi au titre de conservation des dossiers
                                        comptables, à savoir au maximum 10 ans à compter de l’exercice concerné.
                                    </p>
                                    <p>
                                        (3) Nous déployons les mécanismes de contrôle d'accès afin de s'assurer que
                                        seul le personnel habilité peut accéder à vos données personnelles.
                                    </p>
                                    <p>
                                        En cas de violation des données personnelles, eMenila
                                        respectera les exigences légales et réglementaires applicables à la
                                        notification des violations de données personnelles auprès des autorités de
                                        contrôle compétentes et/ou des personnes concernées.
                                    </p>
                                    <p>
                                        <strong>
                                            IV. Où eMenila héberge et transfère vos données
                                            personnelles
                                        </strong>
                                    </p>
                                    <p>
                                        Vos données personnelles seront hébergées au sein des infrastructures
                                        d’hébergement de notre hébergeur, Netlify, situé en Europe De L'ouest.
                                    </p>
                                    <p>
                                        Certains tiers à qui nous communiquons vos données personnelles sont situés
                                        dans des pays tiers à l’Union Européenne, et notamment au Etats-Unis
                                        d'Amérique.
                                    </p>
                                    <p>
                                        Lorsque les produits disponibles à la vente sur le site internet sont
                                        livrés par des ressources situés dans des pays tiers à l’Union Européenne,
                                        nous serons notamment amenés à transférer vos données personnelles vers ces
                                        pays. Il peut également arriver que des prestataires accèdent à vos données
                                        pour notre compte afin de nous fournir un service spécifique et soient
                                        localisés dans des pays tiers à l’Union Européenne.
                                    </p>
                                    <p>
                                        Lorsque de tels transferts existent, nous nous assurons que ces transferts
                                        de données personnelles soient encadrés conformément à la réglementation
                                        applicable afin d’assurer un niveau de protection des données adéquat, soit
                                        par une décision d’adéquation de la Commission européenne, soit par le
                                        biais d’instruments juridiques tels que des contrats de transferts de
                                        données intégrant les Clauses Contractuelles Types de la Commission
                                        Européenne.
                                    </p>
                                    <p>
                                        Pour toute demande concernant les destinataires et les transferts de
                                        données que nous effectuons en dehors de l’Union Européenne, veuillez nous
                                        contacter aux adresses indiquées dans la rubrique ‘’Comment nous
                                        contacter’’ ci-dessous.
                                    </p>
                                    <p>
                                        <strong>
                                            V. Comment vous pouvez gérer vos droits relatifs à vos données
                                            personnelles
                                        </strong>
                                    </p>
                                    <p>
                                        Vous disposez d’un droit d’accès, de rectification, d’effacement, de
                                        limitation, d’opposition concernant le traitement de vos données
                                        personnelles ainsi que du droit de définir des directives relatives au sort
                                        de vos données après votre mort et du droit à la portabilité de vos données
                                        personnelles.
                                    </p>
                                    <p>
                                        Vous pouvez nous contacter à tout moment aux adresses indiquées dans la
                                        rubrique ‘’Comment nous contacter’’ ci-dessous afin d’exercer vos droits en
                                        matière de données personnelles dans les conditions posées par la
                                        réglementation applicable. Vous devez indiquer quel droit vous entendez
                                        exercer ainsi que l’ensemble des précisions nécessaires pour que nous
                                        puissions répondre à votre demande.
                                    </p>
                                    <p>
                                        Ces droits s’exercent dans les conditions posées par la réglementation
                                        applicable.
                                    </p>
                                    <p>
                                        - Le <strong>droit d’accès</strong> signifie que vous pouvez nous demander
                                        à tout moment de vous indiquer si nous traitons des données personnelles
                                        vous concernant et, le cas échéant, de vous indiquer quelles sont les
                                        données personnelles concernées ainsi que les caractéristiques du ou des
                                        traitements effectués.
                                    </p>
                                    <p>
                                        - Le <strong>droit de rectification</strong> signifie que vous pouvez nous
                                        demander la rectification de vos données personnelles lorsqu’elles sont
                                        inexactes. Vous pouvez également demander à ce que vos données
                                        personnelles, dès lors qu’incomplètes, soient complétées dans la mesure où
                                        cela est pertinent au regard de la finalité du traitement en cause.
                                    </p>
                                    <p>
                                        - Le <strong>droit à l’effacement</strong> signifie que vous pouvez
                                        demander d’effacer vos données personnelles notamment lorsque :
                                    </p>
                                    <p>
                                        (i) Leur conservation n’est plus nécessaire au regard des finalités pour
                                        lesquelles elles ont été collectées ;
                                    </p>
                                    <p>
                                        (ii) Vos données personnelles sont traitées sur le fondement de votre
                                        consentement, vous souhaitez retirer ce consentement, et il n’existe pas
                                        d'autre fondement juridique susceptible de justifier le traitement ;
                                    </p>
                                    <p>
                                        (iii) Vous vous êtes opposé au traitement de vos données personnelles et
                                        vous souhaitez en conséquence qu’elles soient effacées ;
                                    </p>
                                    <p>
                                        (iv) Vos données personnelles ont fait l'objet d'un traitement illicite ;
                                    </p>
                                    <p>
                                        (v) Vos données personnelles doivent être effacées pour respecter une
                                        obligation légale qui est prévue soit par le droit de l'Union Européenne
                                        soit par le droit français.
                                    </p>
                                    <p>
                                        - Le <strong>droit à la limitation</strong> signifie que vous pouvez nous
                                        demander de procéder à la limitation du traitement de vos données
                                        personnelles :
                                    </p>
                                    <p>
                                        (i) Lorsque vous contestez l’exactitude de vos données personnelles pendant
                                        une durée nous permettant de vérifier l’exactitude de celles-ci ;
                                    </p>
                                    <p>
                                        (ii) Lorsque suite à un traitement établi comme non conforme, vous préférez
                                        la limitation du traitement à l’effacement complet de vos données
                                        personnelles ;
                                    </p>
                                    <p>
                                        (iii) Lorsque nous n’avons plus besoin de vos données personnelles aux fins
                                        du traitement mais que celles-ci vous sont encore nécessaires pour la
                                        constatation, l’exercice ou la défense de droits en justice ;
                                    </p>
                                    <p>
                                        (iv) Lorsque vous vous êtes opposé au traitement de vos données
                                        personnelles et vous souhaitez une limitation du traitement pendant la
                                        durée nous permettant de vérifier si le motif légitime que vous invoquez se
                                        justifie.
                                    </p>
                                    <p>
                                        La limitation du traitement signifie que le traitement de vos données
                                        personnelles s’entendra alors du seul stockage de vos données personnelles
                                        correspondantes. Nous n’effectuerons alors plus aucune autre opération sur
                                        les données personnelles considérées.
                                    </p>
                                    <p>
                                        - Le <strong>droit d’opposition</strong> signifie que vous pouvez vous
                                        opposer au traitement de vos données personnelles, lorsque ce traitement
                                        est fondé sur la poursuite de l’intérêt légitime de eMenila.
                                        Le droit d’opposition s’exerce sous réserve de justifier d’un motif
                                        légitime tenant à votre situation particulière. Nous cesserons alors le
                                        traitement en cause sauf s’il existe des motifs légitimes et impérieux en
                                        justifiant la poursuite en conformité avec la réglementation applicable.
                                    </p>
                                    <p>
                                        - Le
                                        <strong>
                                            droit de définir des directives relatives au sort de vos données après
                                            votre mort
                                        </strong>
                                        vous permet de faire connaître vos instructions concernant la conservation,
                                        l'effacement et la communication de vos données personnelles après votre
                                        décès.
                                    </p>
                                    <p>
                                        - Le <strong>droit à la portabilité</strong> signifie que vous pouvez nous
                                        demander, dans les conditions posées par la réglementation applicable, de
                                        recevoir vos données personnelles dans un format structuré, couramment
                                        utilisé et lisible par machine, et de vous les transmettre, ou de nous
                                        demander de les transmettre directement à un tiers de votre choix lorsque
                                        cela est juridiquement et techniquement possible.
                                    </p>
                                    <p>
                                        Lorsque nous traitons vos données personnelles sur la base de votre
                                        consentement, vous disposez enfin de la faculté de retirer votre
                                        consentement à tout moment en vous adressant aux adresses indiquées dans la
                                        rubrique ’’Comment nous contacter’’ ou en cliquant sur le lien de
                                        désabonnement présent dans chacune de nos communications.
                                    </p>
                                    <p>
                                        Toutefois, le retrait de votre consentement ne remet pas en cause la
                                        validité du traitement effectué avant ce retrait.
                                    </p>
                                    <p>
                                        <strong>VI. Les mises à jour de cette politique de confidentialité</strong>
                                    </p>
                                    <p>
                                        eMenila se réserve le droit à tout moment de modifier ou
                                        mettre à jour, en tout ou en partie, la présente politique de
                                        confidentialité, en raison de la modification de la réglementation
                                        applicable en matière de protection des données personnelles ou des
                                        traitements de données effectués.
                                    </p>
                                    <p>
                                        Toute modification substantielle de la politique de confidentialité vous
                                        sera notifiée par e-mail lorsque vous nous avez communiqué une adresse mail
                                        valide et sera publiée sur le site internet. Nous vous recommandons de
                                        prendre régulièrement connaissance de la présente politique de
                                        confidentialité afin d’avoir une parfaite connaissance de nos engagements
                                        en matière de sécurité et de protection de vos données personnelles.
                                    </p>
                                    <p>
                                        <strong>VII. Comment nous contacter</strong>
                                    </p>
                                    <p>
                                        Si vous avez des questions, des commentaires ou des suggestions, veuillez
                                        nous contacter en visitant la page contactez-nous ou en les soumettant à    <strong>dev.eMenila@gmail.com</strong>.
                                    </p>
                                    <p>
                                        Ou par courrier postal à 108 Cours Saint-Louis, 33300 Bordeaux, France.
                                    </p>
                                    <p>
                                        Si vous n'êtes pas satisfait de la réponse apportée par Raidouane El
                                        Moukhtari<strong> </strong>à une demande d’exercice de droits conformément
                                        à l’article V ci-dessus ou que vous souhaitez signaler une atteinte à la
                                        réglementation applicable en matière de protection des données, vous
                                        disposez du droit d’introduire une réclamation auprès de la CNIL par
                                        courrier (CNIL - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07) ou
                                        sur son site Internet (www.cnil.fr), ou auprès de l'autorité de protection
                                        des données du pays dans lequel vous résidez ou travaillez habituellement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default LegalNotice;
